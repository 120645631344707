import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/default-page-layout.js";
import PageTitle from '../components/PageTitle';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle mdxType="PageTitle">Zadružni model</PageTitle>
    <h2>{`Naš cilj je prelivanje poslovnih presežkov v korist članov zadruge (nižja cena energije) in razvoj zadruge, ne pa delitev dobička med lastnike.`}</h2>
    <p>{`Zadružni model organizairanosti smo izbrali zato, ker je to edini poslovni model, v katerem lahko člani zadruge, kot kupci blaga ali storitev, soupravljajo organizacijo, ki jim te storitve ali blago prodaja. To pa je največji garant, da bo Sončna zadruga poslovne presežke uporabljala v korist svojih članov in ostala zavezana družbeno odgovornemu odnosu do ljudi in okolja (narave in ljudi), v katerem deluje.`}</p>
    <h2>{`Da bi sledili temu cilju, smo zakonske določbe o zadrugah še dogradili v statutu Sončne zadruge, v katerem smo med drugim določili:`}</h2>
    <ul>
      <li parentName="ul">{`da ima vsak član zadruge na občnem zboru 1 glas, ne glede na število vplačanih vložkov,`}</li>
      <li parentName="ul">{`da je možno med člane zadruge razdeliti največ 20% letnega presežka,`}</li>
      <li parentName="ul">{`da so plače zaposlenih v zadrugi omejene na 4 kratnik najnižje plače v zadrugi,`}</li>
      <li parentName="ul">{`da je članstvo v zadrugi odprto za vsakogar, ki vplača obvezni delež (200 EUR za fizične , 1000 EUR za pravne osebe z do 2 000 000,00 EUR letnih prihodkov in 2000 EUR za pravne osebe z višjimi prihodki).`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      